// Use This for testing
const URL = "pu.bfiinfotech.co.in";
export const BASE_URL = `https://${URL}/api/`;
export const DOMAIN = `https://${URL}`;
export const MAX_COLS = 30;
export const ID_BASE = 1;
export const SRF_ID_BASE = 1;

// TODO: need to move to DB
export const BANK_DETAILS = {
  "Bank Name": "State Bank OF India, CBD Belapur Navi Mumbai",
  "Bank A/c. No.": "39813124209",
  "IFSC Code": "SBIN0061520",
};

export const weightUncertaintySupportiveValues ={
  barometer: 2.4,
  tempIndicator:0.58,
  rhIndicator:1.74,
  weightBox:7950,
};

export const G_Value = 9.80665;

export const GST_NUMBER = "27DSVPK5381Q1ZH";

export const DISABLED_MODULE =["Summary Report","Courier Register","History Card","Expenses","Payment","Client Complaints","Client Feedback","Document Upload","Purchase","Commercial","Payment","Department","DUC Master","MarkAttendance","Attendance","CoordinatesAddress","ClientsUser","WCR Report","Supplier Invoice","Department Analytics","Assigned Duc","Inward Register","SRF_ULR_Register","Training Resources","Datasheet Template","Work Reports"]; 

export const DEVICE_TYPE = ["desktop", "tablet"];

export const LATEST_HASH = "89e09b5893d3f6da022ade0dbd903a52d47c63c6"
